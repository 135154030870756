// extracted by mini-css-extract-plugin
export var machContainer = "index-module--machContainer--xFd3W";
export var machCover = "index-module--machCover--Bm31I";
export var machDescContent = "index-module--machDescContent--q53mx";
export var machDescIcon = "index-module--machDescIcon--RUXlP";
export var machDescItem = "index-module--machDescItem--SzW7S";
export var machDescList = "index-module--machDescList--f-RTu";
export var machDescTitle = "index-module--machDescTitle---9rGW";
export var machItem = "index-module--machItem--cxbKb";
export var machItemContent = "index-module--machItemContent--u++Mc";
export var machItemDesc = "index-module--machItemDesc--N494I";
export var machItemTitle = "index-module--machItemTitle--2POyQ";
export var machOperate = "index-module--machOperate--9Y88T";
export var tradeCard = "index-module--tradeCard--JMhW1";
export var tradeContent = "index-module--tradeContent--3PgPf";
export var tradeCover = "index-module--tradeCover--p3dFj";
export var tradeSubTitle = "index-module--tradeSubTitle--Zjobz";
export var tradeTab = "index-module--tradeTab--0LoUK";
export var tradeTabItem = "index-module--tradeTabItem--Ol3n2";
export var tradeTabItemActive = "index-module--tradeTabItemActive--gC5lF";