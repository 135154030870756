import React, { useState } from 'react';
import BannerWrap, { MoreButton } from '../components/BannerWrap';
import SectionWrap from '../components/SectionWrap';
import IndexBanner from '../assets/images/index-banner.png';
import IndexSectionBg from '../assets/images/index-section-bg-1.png';
import Button from '../components/Button';

import * as styles from './index.module.less';
import { StaticImage } from 'gatsby-plugin-image';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import { Link } from 'gatsby';
import { contactUsModalShow } from '../components/ContactUsModal';

function MachItem({
  title = '',
  desc = '',
  content = '',
  children = <></>,
  cover = <StaticImage className={styles.machCover} src="../assets/images/mach-1.png" alt="" />,
}: {
  title: string;
  desc?: string;
  content: string | React.ReactNode;
  children: React.ReactNode;
  cover?: React.ReactNode;
}) {
  return (
    <div className={styles.machItem}>
      <h4 className={styles.machItemTitle}>{title}</h4>
      <div className={styles.machItemDesc}>
        <b>{desc}</b>
      </div>
      <div className={styles.machItemContent}>{content}</div>
      <div className={styles.machOperate}>{children}</div>
      {cover}
    </div>
  );
}

const TRADE_LIST = [
  {
    name: '政府机关',
    content: {
      title: '各委办局政府信息、历史数据',
      text: '智慧城市、天网工程、雪亮工程等项目建设的海量视频监控正处于智能化升级的阶段，土星云智能化云存储解决方案，既能管理海量的非结构化数据，又能够满足频繁的存、算、检需求，是低成本高效能的视频智能化存储平台。',
    },
  },
  {
    name: '公检司法',
    content: {
      title: '公安、交警、法院、检察院、驾校信息、案件资料、庭审视频',
      text: '土星云一体化数据存储系统，实现对数据管理功能的全面整合。针对公检法对案件管理、视频侦察、刑事技术等大数据存储应用，以及对文档库、图片库、视频库等数据存储管理需求，提供统一的管理界面，以便业务部门对数据进行统一管理，进一步推动数据价值的挖掘。',
    },
  },
  {
    name: '数据中心',
    content: {
      title: '运营商、互联网、第三方海量数据、绿色低碳',
      text: '数字化转型时代，业务场景的变化，数据量爆发性增长，对存储的扩展性、性能、可靠性等方面提出了更高的要求。数据中心的高可靠运行需求，给机房场地环境带来了更高的要求。土星云高稳定、低功耗的特性，符合现阶段发展需求。',
    },
  },
  {
    name: '生物医疗',
    content: {
      title: '医院、制药企业、医疗影像、电子病历',
      text: '现代医疗影像技术的迅猛发展，使得PACS系统已逐渐成为各级医院实现信息化建设的重要组成部分。医疗行业法规的数据保存要求，急需一种新的存储方案来满足应用和发展，土星云在医疗行业拥有成熟的解决方案。',
    },
  },
  {
    name: '遥感高分',
    content: {
      title: '国土、海洋、气象等部门、测绘遥感影像数据',
      text: '近年来卫星导航定位数据、遥感影像数据、地图数据、地名数据等数据服务逐渐丰富，土星云作为大数据智能存储提供商，通过软件定义存储技术为卫星遥感影像的存储、预处理、管理提供了支撑方案和服务。',
    },
  },
  {
    name: '广电媒资',
    content: {
      title: '广电、影视、广告企业、节目编辑、影视素材',
      text: '土星云拥有超稳定的性能，是一款面向海量非结构化数据处理、拥有自主知识产权的企业级高端存储系统，拥有高IO，高稳定、高可靠的特性，解决广电媒体应用中为编辑制作、媒资库、海量视频文件的存储场景需求。',
    },
  },
  {
    name: '档案文博',
    content: {
      title: '博物馆、图书馆、文史馆、档案馆、图书、影像、电子文库',
      text: '档案承载着国家发展的历史，蕴含着中华五千年灿烂文化的底蕴，它记录着人们创造历史的曲折历程和奋战足迹，是弥足珍贵的”中国记忆”，档案的转存、备份及安全防护是一项重要任务。土星云遵循行业标准以及客户要求，为客户提供档案整理服务。',
    },
  },
];

export default function IndexPage() {
  const [tradeActive, setTradeActive] = useState(0);
  const tradeContent = TRADE_LIST[tradeActive];
  return (
    <Layout>
      <BannerWrap
        img={IndexBanner}
        title="土星云分布式存储"
        desc="数据爆发式增长，更多非结构化数据需要长时间保存，需要更安全、可靠、先进、稳定的分布式存储产品。土星云分布式存储让你轻松应对海量非结构化数据存储，满足容量、性能、扩展性、低功耗需求，实现利于当下、着眼未来的永续发展。">
        {/* <MoreButton /> */}
      </BannerWrap>

      <SectionWrap
        title="数据创造价值 创新驱动未来"
        desc="数字转型过程中存储空间是所有IT基础架构的核心，简单快速的数据存取对许多应用来说至关重要">
        <div className={styles.machContainer}>
          <MachItem
            title="分布式存储"
            cover={
              <StaticImage className={styles.machCover} src="../assets/images/mach-2.png" alt="" />
            }
            content={
              <>
                土星云分布式存储是国科环宇自主研发的面向海量非结构化数据的新一代分布式存储产品。采用了基于军工、航天电子技术的低功耗和金属导冷散热设计，搭载了基于“望获”实时操作系统内核的分布式存储软件。该产品同时具备高性能、高可扩展性，高可靠和低能耗等特点，让您轻松应对海量的非结构化数据存储需求，并适应苛刻的户外环境部署。
              </>
            }>
            <Link to="/storage">
              <Button>了解详情</Button>
            </Link>
          </MachItem>
          <MachItem
            title="分布式存储系统"
            content={
              <>
                采用整机柜部署的土星云分布式存储系统产品，全面满足用户高性能、高扩展和高可靠存储需求，帮助客户轻松应对复杂多样化的I/O数据存储需求，PUE值接近理论极限，大幅降低企业TCO。
              </>
            }>
            <Link to="/storage-os">
              <Button>了解详情</Button>
            </Link>
          </MachItem>
        </div>
        <div className={styles.machDescList}>
          <div className={styles.machDescItem}>
            <div className={styles.machDescIcon}>
              <StaticImage width={70} src="../assets/images/mach-desc-1.png" alt="" />
            </div>
            <div className={styles.machDescContent}>
              <div className={styles.machDescTitle}>全场景</div>
              <div>支持块、文件、对象和容器的统一存储，全场景统一管控。</div>
            </div>
          </div>
          <div className={styles.machDescItem}>
            <div className={styles.machDescIcon}>
              <StaticImage src="../assets/images/mach-desc-2.png" alt="" />
            </div>
            <div className={styles.machDescContent}>
              <div className={styles.machDescTitle}>高扩展</div>
              <div>按需弹性扩展，存储规模可达EB级以上。</div>
            </div>
          </div>
          <div className={styles.machDescItem}>
            <div className={styles.machDescIcon}>
              <StaticImage src="../assets/images/mach-desc-3.png" alt="" />
            </div>
            <div className={styles.machDescContent}>
              <div className={styles.machDescTitle}>低功耗</div>
              <div>金属导冷散热技术，有效降低功耗。</div>
            </div>
          </div>
          <div className={styles.machDescItem}>
            <div className={styles.machDescIcon}>
              <StaticImage src="../assets/images/mach-desc-3.png" alt="" />
            </div>
            <div className={styles.machDescContent}>
              <div className={styles.machDescTitle}>高可靠</div>
              <div>内外结构加固设计，确保严苛环境的稳定运行。</div>
            </div>
          </div>
          <div className={styles.machDescItem}>
            <div className={styles.machDescIcon}>
              <StaticImage src="../assets/images/mach-desc-3.png" alt="" />
            </div>
            <div className={styles.machDescContent}>
              <div className={styles.machDescTitle}>强实时</div>
              <div>实时操作系统，响应更快，处理更及时。</div>
            </div>
          </div>
          <div className={styles.machDescItem}>
            <div className={styles.machDescIcon}>
              <StaticImage src="../assets/images/mach-desc-3.png" alt="" />
            </div>
            <div className={styles.machDescContent}>
              <div className={styles.machDescTitle}>自主可控</div>
              <div>采用国产基础硬件+底层软件，产业链自主可控。</div>
            </div>
          </div>
        </div>
      </SectionWrap>

      <SectionWrap
        title="适用行业及场景"
        desc="超级智能存储系统满足各行业数据存储需求，为各行业提供具有大容量、长寿命、高安全、绿色节能等特点的解决方案"
        background={`url(${IndexSectionBg})`}>
        <div className={styles.tradeTab}>
          {TRADE_LIST.map((item, index) => {
            let tradeItemClassName = styles.tradeTabItem;
            let imgPath = `/images/index-trade-icon-${index + 1}`;
            if (tradeActive === index) {
              tradeItemClassName += ' ' + styles.tradeTabItemActive;
              imgPath += '-active';
            }
            return (
              <div
                key={item.name}
                className={tradeItemClassName}
                onClick={() => {
                  setTradeActive(index);
                }}>
                <img alt="" src={`${imgPath}.png`} width={100} />
                <div>{item.name}</div>
              </div>
            );
          })}
        </div>
        <div className={styles.tradeCard}>
          <div>
            <div className={styles.tradeSubTitle}>{tradeContent.content.title}</div>
            <div className={styles.tradeContent}>{tradeContent.content.text}</div>
          </div>
          <div className={styles.tradeCover}>
            <img src={`/images/index-trade-cover-${tradeActive + 1}.jpg`} alt="" />
          </div>
        </div>
      </SectionWrap>

      <SectionWrap
        title="客户实践"
        desc="为医院、教育&政府、物联网、互联网、制造等领域客户提供产品和服务">
        <StaticImage src="../assets/images/index-companies.png" alt="" style={{ width: '100%' }} />
      </SectionWrap>
    </Layout>
  );
}

export function Head() {
  return <SEO />;
}
